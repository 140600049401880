import React from 'react';
import ReactStars from 'react-stars';
import './App.css';
import axios from 'axios';
import Swal from 'sweetalert2';

const StarRating = () => {
  const [form, setForm] = React.useState({
    storeEnvironment: 1,
    productQuality: 1,
    customerService: 1,
    easeOfPurchase: 1,
    convenience: 1,
    mobileNumber: ''
  });

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setForm((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const sendData = () => {
    const data = {
      storeEnvironment: form.storeEnvironment,
      productQuality: form.productQuality,
      customerService: form.customerService,
      easeOfPurchase: form.easeOfPurchase,
      convenience: form.convenience,
      mobileNumber: Number(form.mobileNumber)
    };
    axios.post(`${process.env.REACT_APP_API_URL}/feedback`, data)
      .then((data) => {
        Swal.fire({
          icon: 'success',
          title: 'Thank you for your feedback!',
          text: 'Your feedback has been submitted successfully.',
        }).then(() => {
          window.location.reload();
        })
      })
      .catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong. Please try again later.',
        }).then(() => {
          window.location.reload();
        })
      });
  };

  const handleRatingChange = (name) => (newRating) => {
    setForm((prevState) => ({
      ...prevState,
      [name]: newRating
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if(form.mobileNumber.length !== 10){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter a valid mobile number',
      });
      return;
    }
    if (form.storeEnvironment && form.productQuality && form.customerService && form.easeOfPurchase && form.convenience) {
      sendData()
    }
  };

  return (
    <div className="container mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-center mb-4">Rate Your Experience</h2>
      <form onSubmit={handleSubmit} className="flex flex-col items-center">
        {['storeEnvironment', 'productQuality', 'customerService', 'easeOfPurchase', 'convenience'].map((field) => (
          <div className="form-group mb-4" key={field}>
            <label className="text-purple-700 font-semibold">{field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())}</label>
            <ReactStars
              count={5}
              onChange={handleRatingChange(field)}
              size={24}
              value={form[field]}
              color1={'#e5e5e5'}
              color2={'#8B0A1A'}
              half={true}
              edit={true}
              className="star-rating"
              emptyIcon={<i className="far fa-star"></i>}
              halfIcon={<i className="fa fa-star-half-alt"></i>}
              fullIcon={<i className="fa fa-star"></i>}
            />
          </div>
        ))}

        <div className="form-group text-center mb-4">
          <label className="text-purple-700 font-semibold">Mobile Number</label>
          <input
            type="text"
            maxLength={10}
            name="mobileNumber"
            value={form.mobileNumber}
            onChange={handleFormChange}
            className="form-control border rounded-lg p-2 w-full"
            placeholder="Enter your mobile number"
          />
        </div>

        <button type="submit" className="mt-4 bg-purple-600 hover:bg-purple-700 text-white font-bold py-2 px-6 rounded-lg transition duration-300">
          Submit
        </button>
      </form>
      <div className="text-center text-sm mt-6">
        <p className="text-gray-600">Version 1.0.0</p>
        <p className="text-gray-600">© {new Date().getFullYear()} Rajasthan Lime Udyog - All rights reserved</p>
      </div>
    </div>
  );
};

export default StarRating;
